import React from 'react';
import './index.css'; // Assuming Tailwind CSS is imported in index.css

function App() {
  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <h1 className="text-white text-4xl font-bold">
        Taylor Swift AI App
      </h1>
    </div>
  );
}

export default App;
